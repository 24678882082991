<template>
  <div class="container-fluid">
    <div class="row">
      <div v-if="isMoveSelectedItems" class="col-md-12 px-0 mb-1">
        <div id="infoToolbar" class="media-list media-bordered bg-info my-1 p-1">
          <div class="d-flex align-items-center">
            <p-icon name="bi-info" color="white" size="24px" class="round bg-dark" />
            <div class="text-white px-2">
              Items in this batch were not uploaded before.
              But these items have been sent to FBA.
              Please move these items to your batch list for your own record.
              Select items and create a new batch using
              <i class="dx-icon-movetofolder bg-danger round p-half" style="font-size:20px" />
              button.
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 px-0">
        <dx-data-grid
          id="shipmentItemsGrid"
          ref="shipmentItemsGridRef"
          :data-source="dataSource"
          :height="`calc(100vh - ${offset})`"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @cell-dbl-click="onCellDblClick"
          @toolbar-preparing="onToolbarPreparing($event)"
          @exporting="onExporting"
          @option-changed="onOptionChanged"
          @editor-preparing="onEditorPreparing"
        >
          <!--region    ::DataGird base components -->
          <dx-search-panel
            :highlight-search-text="true"
            :visible="true"
            :width="200"
            :text="filters.query"
            placeholder="Search..."
          />
          <dx-sorting mode="single" />
          <dx-state-storing
            :enabled="false"
            type="custom"
            :custom-load="loadDataGridState"
            :custom-save="saveDataGridState"
          />
          <dx-scrolling mode="virtual" row-rendering-mode="virtual" />
          <dx-remote-operations :paging="true" :filtering="false" :sorting="true" :summary="false" />
          <dx-column-chooser :enabled="true" mode="select" title="Column Chooser" />
          <dx-selection
            show-check-boxes-mode="always"
            select-all-mode="page"
            :allow-select-all="true"
            :width="10"
            mode="multiple"
          />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
          <template #action-buttons="{ data }">
            <div>
              <div v-for="item in data" :key="item.id" style="padding: 4px">
                <dx-util-button :id="item.btnId" :text="item.name" :icon="item.icon" :visible="item.visible" :disabled="item.disabled" class=" w-100 text-left" styling-mode="text" @click="setToolbarActionsByItem(item)" />
              </div>
            </div>
          </template>
          <!--endregion ::DataGird base components -->
          <dx-column data-field="smallImage" cell-template="imageCellTemplate"
                     :width="100" caption="Image"
                     alignment="center" :allow-sorting="false"
          />
          <template #imageCellTemplate="{data}">
            <p-lazy-img width="72px" height="72px" :src="data.value || defaultImg" />
          </template>
          <dx-column data-field="batchName" :visible="hideBatchName"
                     :show-in-column-chooser="false"
                     cell-template="batchNameTemplate"
          />
          <template #batchNameTemplate="{data}">
            <a href="#"
               class="font-weight-bold d-block text-nowrap"
               @click="goToBatch(data)"
            >
              {{ data.value }}
            </a>
          </template>
          <dx-column data-field="title" cell-template="titleCellTemplate" :min-width="300" />
          <template #titleCellTemplate="{data}">
            <span role="button" class="dx-product-title text-primary" @click="openProductDetails(data.data)">
              {{ data.value }}
            </span>
          </template>
          <dx-column
            data-field="amzRank"
            cell-template="rankingCellTemplate"
            width="80"
            caption="Rank"
            alignment="center"
            :allow-sorting="false"
          />
          <template #rankingCellTemplate="{data}">
            {{ data.value || '-' }}
          </template>
          <dx-column data-field="tracking" caption="Tracking" width="140" cell-template="trackingTemplate" />
          <template #trackingTemplate="{data}">
            <edit-in-place :row="data" field-name="tracking"
                           @emit-updated="getBySearchTerm"
                           @emit-search-by-tracking="searchByTracking"
            />
          </template>
          <dx-column data-field="orderNumber" caption="Order #" cell-template="orderNumberTemplate" :width="140" />
          <template #orderNumberTemplate="{data}">
            <edit-in-place :row="data" field-name="orderNumber"
                           @emit-updated="getBySearchTerm"
                           @emit-search-by-orderNumber="searchByOrderNumber"
            />
          </template>
          <dx-column data-field="purchaseDate" caption="Purchased At"
                     data-type="date" :allow-filtering="false"
                     :allow-sorting="false"
          />
          <dx-column data-field="shipmentDate" caption="Shipped At"
                     data-type="date" :allow-filtering="false"
                     :allow-sorting="false"
          />
          <template #header-count-in-bundle="{ data }">
            <div v-b-tooltip.d100.hover.bottom.v-primary title="Total count used in Bundle/MultiPack">
              {{ data.column.caption }}
            </div>
          </template>
          <dx-column data-field="quantity" caption="Expected" :allow-filtering="false" />
          <dx-column data-field="sortedCount" caption="Sorted" :allow-filtering="false" />
          <dx-column data-field="receivedCount" caption="Received" :allow-filtering="false" />
          <dx-column data-field="damagedCount" caption="Problem" :allow-filtering="false" />
          <dx-column data-field="shippedCount" caption="Shipped" :allow-filtering="false" />
          <dx-column data-field="deliveredCount" caption="Delivered"
                     header-cell-template="amazon-fba-delivered"
                     :allow-filtering="false" :allow-sorting="false"
          />
          <template #amazon-fba-delivered="{ data }">
            <div v-b-tooltip.d100.hover.bottom.v-primary title="Item count delivered to Amazon Fulfillment Centers">
              {{ data.column.caption }}
            </div>
          </template>
          <dx-column :visible="isDownloaded" caption="Inventory" :calculate-cell-value="calculateInventory" />
          <dx-column caption="Discrepancy" :calculate-cell-value="calculateDiscrepancy"
                     cell-template="discrepeancyTemplate"
          />
          <template #discrepeancyTemplate="{ data }">
            <span :class="formatDiscrepancyCell(data.value)">
              {{ data.value }}
            </span>
          </template>
          <dx-column data-field="status" cell-template="statusTemplate" :allow-filtering="false" />
          <template #statusTemplate="{ data }">
            <dx-util-button
              :text="formatStatus(data.value)"
              :type="resolveShipDateVariant(data)"
              :element-attr="{class: 'btn-action-xs'}"
              width="100%"
              hint="Click this button to block/unblock items."
              @click="onBlockUnblockAction(data)"
            />
          </template>
          <dx-column v-if="needsActionOrReturn" data-field="resolution"
                     cell-template="resolutionTemplate"
                     :allow-filtering="false" width="140"
          />
          <template #resolutionTemplate="{data}">
            <div>
              <dx-util-select-box :value="data.value" :data-source="resolutionOptions"
                                  display-expr="text"
                                  value-expr="value" :class="resolveResolution(data.value)"
                                  @value-changed="updateResolutionStatus($event, data.data.id)"
              />
            </div>
          </template>
          <dx-column data-field="asin" width="120" caption="ASIN" cell-template="cellASIN" />
          <template #cellASIN="{ data }">
            <div>
              <dx-util-drop-down-button v-if="data.data.asinType && data.data.asinType === 'PARENT'"
                                        text="PARENT" :drop-down-options="{width:400}"
                                        drop-down-content-template="parentAsinTemplate"
              >
                <template #parentAsinTemplate>
                  <div class="p-1">
                    <h4 class="text-warning">
                      The uploaded ASIN is a Parent ASIN. Please find the actual asin <br>
                      from original Amazon listing and update it. <br>
                      The service provide may incure extra charges for the data correction.
                    </h4>
                    <div class="py-1">
                      <a :href="`https://www.amazon.com/gp/product/${data.value}`" target="_blank">
                        <span>
                          Existing Parent ASIN: {{ data.value }}
                        </span>
                        <p-icon name="feather-external-link" size="14px" />
                      </a>
                    </div>
                    <div>
                      <h6> Enter New ASIN:</h6>
                      <dx-util-text-box id="newAsin" ref="newAsinRef" v-model="newAsin">
                        <dx-text-box-button
                          name="newAsin"
                          :options="newAsinButtonOptions(data)"
                          location="after"
                        />
                      </dx-util-text-box>
                    </div>
                  </div>
                </template>
              </dx-util-drop-down-button>
              <div v-if="!data.data.asinType || data.data.asinType === 'CHILD'"
                   class="d-flex flex-sm-row justify-content-between align-items-center mb-0"
              >
                <a :href="`https://www.amazon.com/gp/product/${data.value}`" target="_blank">
                  <span
                    v-b-tooltip.d800.hover.top.v-secondary="'See Item on Amazon'"
                    class="text-capitalize badge badge-pill badge-light-info"
                  >
                    {{ data.value }}
                  </span>
                </a>
              </div>
            </div>
          </template>
          <dx-column data-field="upc" caption="UPC" :width="120" cell-template="upcCellTemplate" />
          <template #upcCellTemplate="{data}">
            <a target="_blank" :href="`https://www.barcodelookup.com/${data.value}`">
              {{ data.value }}
            </a>
          </template>
          <dx-column data-field="msku" caption="MSKU" width="120" cell-template="mskuTemplate" />
          <template #mskuTemplate="{data}">
            <div v-if="data.value">
              {{ data.value }}
            </div>
            <div v-else>
              <dx-util-button
                :text="`SET`"
                :type="`default`"
                width="100%"
                hint="Click this button to set/add MSKU."
                @click="onMskuAction(data)"
              />
            </div>
          </template>
          <dx-column data-field="fnsku" caption="FNSKU" width="110" />
          <dx-column
            data-field="countPerBundle"
            caption="C/B,MP"
            cell-template="CountInBundleTemplate"
            header-cell-template="header-count-per-bundle"
            :allow-filtering="false"
            alignment="center"
          />
          <template #header-count-per-bundle="{ data }">
            <div v-b-tooltip.d100.hover.bottom.v-primary title="Count used per Bundle/MultiPack">
              {{ data.column.caption }}
            </div>
          </template>
          <dx-column
            data-field="countInBundle"
            caption="InBundle"
            cell-template="CountInBundleTemplate"
            header-cell-template="header-count-in-bundle"
            :allow-filtering="false"
            alignment="center"
          />
          <template #CountInBundleTemplate="{ data }">
            <span
              v-if="data.value > 0"
              :class="`${resolveBundleMultiPackVariant(data.value)}`"
              class="text-capitalize badge badge-pill"
            >
              {{ data.value }}
            </span>
          </template>
          <dx-column data-field="serialNo" width="80" />
          <dx-column data-field="palletId" caption="Pallet Id" cell-template="palletIdTemplate" />
          <template #palletIdTemplate="{data}">
            <a href="#"
               class="font-weight-bold d-block text-nowrap"
               @click="searchByPalletId(data)"
            >
              {{ data.value }}
            </a>
          </template>
          <dx-column data-field="orderNumberSold" caption="Order # Sold" />
          <dx-column data-field="expirationDate" caption="Exp Date" data-type="date" :allow-filtering="false" />
          <dx-column data-field="productType" width="150" caption="Category" alignment="left" :allow-sorting="false" />
          <dx-column data-field="supplier" />
          <dx-column data-field="itemCondition" caption="Cond" :allow-filtering="false" />
          <dx-column data-field="fragile" :allow-filtering="false" />
          <dx-column data-field="packType" caption="Packing" width="70" cell-template="cellPackType" />
          <template #cellPackType="{ data }">
            <span v-if="data.value === 'individual'" v-b-tooltip.d800.hover.left.v-secondary="'Individual pack'"
                  class="d-block badge badge-light-secondary"
            >
              Single
            </span>
            <span v-else-if="data.value === 'bundle'" class="d-block badge badge-light-secondary">
              Bundle
            </span>
            <span v-else-if="data.value === 'multi_pack'" v-b-tooltip.d800.hover.left.v-secondary="'Multi Pack'"
                  class="d-block badge badge-light-secondary"
            >
              MP
            </span>
          </template>
          <dx-column data-field="itemCost" caption="Cost"
                     :format="{ type: 'currency', precision: 2 }"
                     :allow-filtering="false"
          />
          <dx-column data-field="salePrice" :format="{ type: 'currency', precision: 2 }" :allow-filtering="false" />
          <dx-column data-field="action" />
          <dx-column data-field="creationTime" caption="Created At" data-type="date" />
          <dx-column data-field="notes" />
          <dx-column data-field="tags" />
          <dx-column data-field="purchasedBy" />
          <dx-column data-field="source" />
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="90" />
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                type="warning" icon="bi bi-pencil-square"
                class="mr-half" hint="Edit"
                @click="editSelectedItem(data)"
              />
              <dx-util-button :disabled="allowDeleting(data)"
                              type="danger" icon="bi bi-trash"
                              hint="Delete"
                              @click="deleteItem(data)"
              />
            </div>
          </template>
          <template #toolbarFilters>
            <div class="d-flex flex-row align-items-center">
              <div class="mr-half">
                <dx-util-button icon="back" type="default" class="mx-half" @click="goToShipmentBatches" />
              </div>
              <div class="mr-1">
                <dx-util-tag-box
                  v-model="filters.statusList"
                  :data-source="itemStatuses"
                  display-expr="text"
                  value-expr="value"
                  class="mx-half" style="min-width: 300px;"
                  placeholder="Select status"
                  :show-selection-controls="true"
                  @value-changed="getBySearchTerm"
                />
              </div>
              <date-selection default-date="current_month" @setdates="onDateSelection" />
            </div>
          </template>
        </dx-data-grid>
      </div>
      <div class="col-md-12 px-0">
        <dx-util-popup
          :visible="popupVisible"
          :show-close-button="false"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          :width="480"
          :height="300"
          title="Move Items"
        >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item
            widget="dxButton"
            toolbar="bottom"
            location="after"
            :options="cancelButtonOptions"
          />
          <dx-util-toolbar-item
            widget="dxButton"
            toolbar="bottom"
            location="after"
            :options="saveButtonOptions"
          />
          <form @submit="handleSubmit">
            <h5 class="text-danger">
              A new batch will be created on your behalf and all the selected
              items will be moved to the newly created batch. You can update any
              item related information after the new batch is created. Please
              type a new batch name below.
            </h5>
            <dx-util-form
              ref="productFormRef"
              :form-data="moveItems"
              :col-count="1"
              label-location="top"
            >
              <dx-util-simple-item
                data-field="batchName"
                editor-type="dxTextBox"
                :label="{ text: 'New Batch Name', location: 'top' }"
              >
                <dx-util-required-rule message="Batch Name is required" />
              </dx-util-simple-item>
            </dx-util-form>
          </form>
        </dx-util-popup>
      </div>
      <div class="col-md-12 px-0">
        <light-box
          :items="images"
          :index="index"
          :effect="'fade'"
          @close="index = null"
        />
      </div>
      <shipment-item-form :id="itemId"
                          :component-id="shipmentItemFormComponentId" :batch-id="selectedBatchId"
                          @create-or-update-item="onCreateOrUpdateItem"
                          @grid-refresh-required="getBySearchTerm"
      />
      <div class="col-md-12 px-0">
        <dx-util-popup
          ref="itemStatusUpdatePopupRef"
          :show-close-button="false"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          :title="itemStatusUpdateTitle"
          :width="560"
          :height="320"
        >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom"
                                location="after"
                                :options="cancelItemStatusButtonOptions"
          />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom"
                                location="after"
                                :options="saveItemStatusButtonOptions"
          />
          <form method="post" @submit="handleBlockUnblockItemStatus">
            <dx-util-form ref="itemStatusUpdatePopupFormRef" :form-data="itemStatusUpdatePopupForm"
                          :col-count="1"
                          :show-colon-after-label="true" label-location="top"
                          validation-group="companyData"
            >
              <dx-util-item data-field="notes" :editor-options="{ height: 160 }"
                            editor-type="dxTextArea"
                            :label="{text: 'Notes'}"
              >
                <dx-util-required-rule message="Notes is required" />
              </dx-util-item>
            </dx-util-form>
          </form>
        </dx-util-popup>
      </div>
      <div class="col-md-12 px-0">
        <dx-util-popup
          ref="setMskuPopupRef"
          :show-close-button="true"
          :drag-enabled="false"
          :close-on-outside-click="true"
          :show-title="true"
          :title="setMskuTitle"
          :width="560"
          height="auto"
          :on-showing="onSetMskuPopupShowing"
          :on-hiding="onSetMskuPopupHiding"
        >
          <dx-util-position at="center" my="center" />
          <div class="pb-1">
              <span>
              ASIN: <b class="text-warning">{{ selectedAsin }}</b>
            </span>
            <span class="px-1">
              UPC: <b class="text-warning">{{ selectedUpc }}</b>
            </span>
          </div>
          <div v-show="skuPairs.length>0">
            <b-table small striped hover :items="skuPairs" :fields="skuPairFields">
              <template #cell(action)="row">
                <dx-util-button
                  :text="'Select'"
                  :type="'default'"
                  :element-attr="{class: 'btn-action-xs'}"
                  width="100%"
                  hint="Click this button to block/unblock items."
                  @click="setMskuFnskuPair(row.item)"
                />
              </template>
            </b-table>
            <hr class="mt-1 mb-2">
          </div>
          <div class="mb-1">
              <span v-if="skuPairs.length===0" class="text-warning">
              This product does not have any MSKUs/FNSKUs.</span>
            If you want to create a MSKU/FNSKU for this product you
            can use the form below.
          </div>
          <div>
            <dx-util-validation-group class="row pb-1">
              <div class="col-6">
                <dx-util-text-box
                  id="msku"
                  v-model="msku"
                  styling-mode="filled"
                  label="MSKU"
                  label-mode="floating"
                >
                  <dx-util-validator>
                    <dx-util-required-rule message="MSKU is required" />
                  </dx-util-validator>
                </dx-util-text-box>
              </div>
              <div class="col-3 p-0">
                <dx-util-text-box
                  id="fnsku"
                  v-model="fnsku"
                  styling-mode="filled"
                  label="FNSKU"
                  label-mode="floating"
                />
              </div>
              <div class="col-3">
                <dx-util-button
                  text="Create New"
                  type="default"
                  styling-mode="contained"
                  :element-attr="{class: 'btn-block'}"
                  @click="createMskuFnskuPair"
                />
              </div>
            </dx-util-validation-group>
          </div>
        </dx-util-popup>
      </div>
    </div>
    <!--Begin:: Product Details -->
    <product-details :product-id="selectedProductId"
                     :show-product="isVisible"
                     @close="isVisible=false,selectedProductId=null"
    />
    <!--End:: Product Details -->
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import shipService from '@/http/requests/ship/shipService'
import maintenanceService from '@/http/requests/common/maintenanceService'
import productsService from '@/http/requests/product/productsService'
import store from '@/store'
import DateSelection from '@/views/dashboard/statistics/components/DateSelection.vue'
import CoolLightBox from 'vue-cool-lightbox'
import { exportDataGrid } from 'devextreme/excel_exporter'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import { formatDate, currencyFormatter } from '@core/utils/filter'
import Pager from '@core/dev-extreme/utils/pager'
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import moment from 'moment'
import { confirm } from 'devextreme/ui/dialog'
import { Notify } from '@robustshell/utils/index'
import settingsService from '@/http/requests/common/settings'
import SettingsTypeEnum from '@/enums/settingsTypeEnum'
import referenceTypeEnum from '@/enums/referenceTypeEnum'
import { getAmzResolutionList } from '@/enums/amzResolution.enum'
import { DataGridNamesEnum } from '@/enums'
// import { DxProgressBar } from 'devextreme-vue/progress-bar'
import useCurrentUser from '@/libs/app/current-user'
import { v4 as uuidv4 } from 'uuid'
import { shipmentItemsSource } from './shipmentItemsStore'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import ClientShipmentItemStatusEnum, { getClientShipmentItemStatusEnumList } from '~/enums/clientShipmentItemStatusEnum'
import { isValidAsin } from '../validationItem'
import EditInPlace from './EditInPlace'

const statusFormat = ratio => `Delivered: ${(ratio * 100).toFixed(2)}%`
const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    'date-selection': DateSelection,
    'edit-in-place': EditInPlace,
    'light-box': CoolLightBox,
    'product-details': () => import('@/views/apps/product/products/components/ProductDetails.vue'),
    'shipment-item-form': () => import('./ShipmentItemForm.vue'),
    'dx-text-box-button': DxTextBoxButton,
  },
  mixins: [GridBase],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    shipBatchId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup() {
    const {
      userId,
      userCompanyId,
    } = useCurrentUser()
    return {
      userId,
      userCompanyId,
    }
  },
  data: () => ({
    popupVisible: false,
    hideBatchName: false,
    showPageProperties: false,
    dataSource: [],
    filters: {
      query: '',
      statusList: [0, 1, 2, 7, 10],
      beginDate: '',
      endDate: '',
    },
    grid: {
      query: '',
      selectedItem: {
        id: 0,
      },
    },
    isSearchAction: false,
    includeAllBatches: false,
    batchId: 0,
    selectedBatchId: 0,
    itemId: null,
    batchName: '',
    packType: '',
    searchCode: '',
    leftDay: 0,
    isDownloaded: false,
    isDownloadedMessage: false,
    selectedRows: [],
    isMoveSelectedItems: false,
    readyToClose: false,
    complete: false,
    moveItems: {
      batchName: '',
    },
    shipDate: null,
    createOrEditItemTitle: '',
    defaultImg: defaultImageUrl,
    images: [],
    index: null,
    itemStatusUpdateTitle: '',
    itemStatusUpdatePopupForm: {
      productId: 0,
      status: 0,
      notes: '',
    },
    newAsin: '',
    setMskuTitle: 'Set MSKU',
    selectedProductId: null,
    selectedItem: {},
    selectedItemId: null,
    selectedUpc: '',
    selectedAsin: '',
    skuPairs: [],
    showFoundQty: false,
    skuPairFields: [
      {
        key: 'msku',
        label: 'MSKU',
        sortable: false,
        class: 'text-left',
        thStyle: { width: '50%' },
      },
      {
        key: 'fnsku',
        label: 'FNSKU',
        sortable: false,
        class: 'text-left',
        thStyle: { width: '25%' },
      },
      {
        key: 'action',
        label: 'Action',
        class: 'text-center',
        thStyle: { width: '25%' },
      },
    ],
    msku: '',
    fnsku: '',
    isVisible: false,
    shipmentItemFormComponentId: '',
    totalBatchCost: 0,
    totalBatchSalePrice: 0,
    avgCost: 0,
    avgSalePrice: 0,
    dataGridState: {},
    batchSellRatio: 0,
    batchStatPercents: [],
    batchStatCounts: [],
    deliveredPercent: 0,
    statusFormat,
    currentGridStateId: null,
    resolutionOptions: getAmzResolutionList(),
  }),
  computed: {
    itemStatuses() {
      return getClientShipmentItemStatusEnumList()
        .filter(el => el.value !== 12)
    },
    dataGrid() {
      const dataGrid = this.$refs.shipmentItemsGridRef.instance
      return dataGrid
    },
    remainingReconcileDay() {
      const duration = 21 - moment()
        .diff(moment(parseInt(this.shipDate, 10))
          .format('L'), 'days')
      const remaining = duration < 0 ? 0 : duration
      const durationClose = 42 - moment()
        .diff(moment(parseInt(this.shipDate, 10))
          .format('L'), 'days')
      const remainingClose = durationClose < 0 ? 0 : durationClose
      return `This batch can be reconciled after ${remaining} and closed after ${remainingClose} days.`
    },
    remainingCloseDay() {
      const duration = 42 - moment()
        .diff(moment(parseInt(this.shipDate, 10))
          .format('L'), 'days')
      const remaining = duration < 0 ? 0 : duration
      return `This batch can be reconciled after ${remaining} days.`
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleSubmit(e.event)
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.popupVisible = false
        },
      }
    },
    saveItemStatusButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: () => {
          this.handleBlockUnblockItemStatus()
        },
      }
    },
    cancelItemStatusButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          const popup = this.$refs.itemStatusUpdatePopupRef.instance
          popup.hide()
        },
      }
    },
    offset() {
      return this.isMoveSelectedItems ? '10rem' : '17rem'
    },
    needsActionOrReturn() {
      return this.$route.params.status === 'needs_action'
        || this.$route.params.status === 'return_removal'
        || this.$route.params.status === 'removal'
    },
  },
  watch: {
    includeAllBatches(newValue) {
      if (newValue) {
        this.getBySearchTerm()
      } else {
        this.hideBatchName = false
        this.getBySearchTerm()
      }
    },
  },
  mounted() {
    this.initialLoad()
  },
  created() {
    this.isMoveSelectedItems = this.$route.params.status === 'needs_action'
    this.complete = this.$route.params.status === 'complete'
    if (this.shipBatchId === null) {
      const getQuery = store.getters['appLocal/getUploadedShipmentItemsParams']
      if (getQuery.batchId) {
        this.showPageProperties = true
        this.batchId = getQuery.batchId
        this.batchName = getQuery.batchName
        this.shipDate = getQuery.shipDate
      } else {
        this.$router.push({ name: 'uploaded-batches' })
      }
    } else {
      this.showPageProperties = false
      this.batchId = this.shipBatchId
    }

    this.downloaded()
    this.canClose()
    this.getShipmentPlanStats()
  },
  methods: {
    onDateSelection(e) {
      this.filters.beginDate = e.beginDate
      this.filters.endDate = e.endDate
      this.getBySearchTerm()
    },
    getCurrencyFormat(value) {
      return currencyFormatter(value)
    },
    fixParentChildAsinIssue(data) {
      if (!isValidAsin(this.newAsin)) {
        Notify.error('Please enter a valid ASIN')
        return
      }
      if (data.data.asin === this.newAsin) {
        Notify.error('You cannot enter same ASIN')
        return
      }
      const params = {
        companyId: data.data.companyId,
        oldAsin: data.data.asin,
        newAsin: this.newAsin,
      }
      maintenanceService.fixParentChildAsinIssue(params)
        .then(() => {
          this.newAsin = ''
        })
    },
    newAsinButtonOptions(data) {
      return {
        icon: 'save',
        type: 'success',
        onClick: () => this.fixParentChildAsinIssue(data),
      }
    },
    // async setActionByItem(item) {
    //   switch (item.id) {
    //     case 'resetDatagridState':
    //       await settingsService.delete(this.currentGridStateId)
    //       window.location.reload()
    //       break
    //     default:
    //       break
    //   }
    // },
    onCellDblClick(e) {
      if (e.rowType === 'data' && e.column.dataField === 'smallImage') {
        if (e.eventType === 'dxdblclick') {
          if (e.data.largeImage) {
            this.images.length = 0
            this.index = 0
            this.images.push(e.data.largeImage)
          }
        }
      }
    },
    goToShipmentBatches() {
      this.$router.go(-1)
    },
    initialLoad() {
      this.hideBatchName = false
      this.downloaded()
      const filters = {
        companyId: 0,
        isUpc: false,
        statusList: this.filters.statusList,
        includeSortedItems: false,
        includeAllBatches: false,
        batchId: this.batchId,
        q: '',
      }
      // const dataSource = this.dataGrid.getDataSource()
      shipmentItemsSource.searchValue(filters)
      shipmentItemsSource.load()
      this.dataSource = shipmentItemsSource
    },
    reload() {
      const filters = {
        companyId: 0,
        isUpc: false,
        status: this.filters.statusList,
        includeSortedItems: false,
        includeAllBatches: false,
        batchId: this.batchId,
        q: '',
      }
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.reload()
    },
    downloaded() {
      if (this.showPageProperties) {
        const duration = moment()
          .diff(
            moment(parseInt(this.shipDate, 10))
              .format('L'),
            'days',
          )
        this.isDownloaded = duration >= 21
        this.isDownloadedMessage = this.isDownloaded
      }
    },
    canClose() {
      const duration = moment()
        .diff(
          moment(parseInt(this.shipDate, 10))
            .format('L'),
          'days',
        )
      this.readyToClose = duration >= 42
    },
    allowDeleting(e) {
      return (
        e.data.damagedCount > 0
        || e.data.receivedCount > 0
        || e.data.shippedCount > 0
      )
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.filters.query = query.trim()
            e.component.searchByText('')
            this.getBySearchTerm()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.filters.query = query.trim()
            this.getBySearchTerm()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.filters.query = ''
            this.getBySearchTerm()
          }
        }
      }
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)
      if (this.showPageProperties) {
        toolbarItems.unshift({
          location: 'before',
          template: 'toolbarFilters',
        })
      }
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          id: 'editActions',
          text: 'Actions',
          icon: 'menu',
          width: 120,
          items: [
            {
              id: 'create',
              btnId: 'btn-create-new',
              name: 'Add New Item',
              icon: 'icon bi-plus-lg',
              class: 'rounded-0 p-0 text-left',
              elementAttr: {
                class: 'p-0',
              },
            },
            {
              id: 'delete',
              btnId: 'btn-delete-items',
              name: 'Delete Items',
              icon: 'trash',
              // visible: this.hasAdminPermission,
              class: 'rounded-0 p-0 text-left',
              elementAttr: {
                class: 'p-0',
              },
            },
            {
              id: 'reconcile',
              btnId: 'btn-reconcile',
              name: 'Reconcile Batch',
              visible: this.showPageProperties,
              disabled: !this.isDownloaded || this.complete,
              hint: 'This batch is ready to reconcile',
              icon: 'icon bi-list-check',
              class: 'rounded-0 p-0 text-left',
              elementAttr: {
                class: 'p-0',
              },
            },
            {
              id: 'close',
              btnId: 'btn-close',
              name: 'Close Shipment Plan',
              disabled: !this.readyToClose || this.complete,
              visible: this.showPageProperties,
              icon: 'icon bi-check-square',
              class: 'rounded-0 p-0 text-left',
              elementAttr: {
                class: 'p-0',
              },
            },
            {
              id: 'move',
              btnId: 'btn-move',
              name: 'Move Item',
              disabled: !this.readyToClose || this.complete,
              visible: this.isMoveSelectedItems,
              icon: 'movetofolder',
              class: 'rounded-0 p-0 text-left',
              elementAttr: {
                class: 'p-0',
              },
            },
          ],
          type: 'default',
          stylingMode: 'contained',
          hint: 'Actions for multiple orders',
          dropDownOptions: { width: 'auto' },
          dropDownContentTemplate: 'action-buttons',
        },
        location: 'after',
        // visible: this.hasPermission,
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          text: 'Export',
          icon: 'exportxlsx',
          items: [
            {
              id: 'all',
              name: 'All Items',
              icon: 'selectall',
            },
            {
              id: 'selected',
              name: 'Selected',
              icon: 'exportselected',
            },
            {
              id: 'forUpload',
              name: 'For Upload',
              icon: 'icon bi-cloud-upload',
            },
          ],
          width: 140,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Download Shipping Plan Items',
          onItemClick: arg => {
            if (arg.itemData.id === 'all') {
              this.onDownloadItems()
            } else if (arg.itemData.id === 'selected') {
              this.onDownloadSelectedItems()
            } else if (arg.itemData.id === 'forUpload') {
              this.downloadForUpload()
            }
          },
        },
        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxCheckBox',
        cssClass: 'd-flex align-self-center',
        options: {
          text: 'All Batches',
          hint: 'Check to search in all batches',
          value: false,
          onValueChanged: c => {
            this.includeAllBatches = c.value
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          visible: this.showPageProperties,
          icon: 'refresh',
          hint: 'Reload Data',
          onClick: () => {
            this.dataGrid.refresh()
              .then(() => {
                this.hideBatchName = false
                this.filters.query = ''
                this.getBySearchTerm()
              })
          },
        },
        location: 'after',
      })
    },
    async setToolbarActionsByItem(item) {
      switch (item.id) {
        case 'create':
          this.createNewRecord()
          break
        case 'delete':
          this.deleteBatchItemsInBulk()
          break
        case 'reconcile':
          await this.onReconcileExporting()
          break
        case 'close':
          await this.onCloseBatch()
          break
        case 'move':
          this.onClickMoveSelectedItem()
          break
        default:
          break
      }
    },
    createNewRecord() {
      this.shipmentItemFormComponentId = uuidv4()
      this.selectedBatchId = Number(this.batchId)
    },
    async onDownloadItems(e) {
      const filters = {
        companyId: 0,
        isUpc: false,
        statusList: this.filters.statusList,
        includeSortedItems: false,
        includeAllBatches: this.includeAllBatches,
        includeAllItems: true,
        batchId: this.batchId,
        q: '',
      }
      const pager = new Pager({ staticPageSize: 10000 })
      const response = await shipService.getBySearchList(filters, pager.staticPageable)
      const data = response.data
      this.onDownloadExportData(data)
    },
    onDownloadSelectedItems() {
      const data = this.dataGrid.getSelectedRowsData()
      if (Array.isArray(data) && data.length > 0) {
        this.onDownloadExportData(data)
      } else {
        Notify.warning('Please select at least one item to export data')
      }
    },
    onDownloadExportData(data) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Shipment Items')
      worksheet.columns = [
        {
          header: 'Tracking',
          key: 'TrackingNumber',
          width: 14,
        },
        {
          header: 'Order Number',
          key: 'OrderNumber',
          width: 16,
        },
        {
          header: 'OrderNumberSold',
          key: 'OrderNumberSold',
          width: 16,
        },
        {
          header: 'PalletId',
          key: 'PalletId',
          width: 16,
        },
        {
          header: 'PurchaseDate',
          key: 'PurchaseDate',
          width: 12,
        },
        {
          header: 'ShipmentDate',
          key: 'ShipmentDate',
          width: 12,
        },
        {
          header: 'ExpirationDate',
          key: 'ExpirationDate',
          width: 12,
        },
        {
          header: 'ASIN',
          key: 'ASIN',
          width: 13,
        },
        {
          header: 'MSKU',
          key: 'MSKU',
          width: 18,
        },
        {
          header: 'UPC',
          key: 'UPC',
          width: 14,
        },
        {
          header: 'FNSKU',
          key: 'FNSKU',
          width: 13,
        },
        {
          header: 'SerialNo',
          key: 'SerialNo',
          width: 12,
        },
        {
          header: 'ProductTitle',
          key: 'Title',
          width: 80,
        },
        {
          header: 'Supplier',
          key: 'Supplier',
          width: 18,
        },
        {
          header: 'QTY',
          key: 'Quantity',
          width: 8,
        },
        {
          header: 'Condition',
          key: 'Condition',
          width: 12,
        },
        {
          header: 'Cost',
          key: 'Cost',
          width: 12,
        },
        {
          header: 'SalePrice',
          key: 'SalePrice',
          width: 12,
        },
        {
          header: 'Fragile',
          key: 'Fragile',
          width: 8,
        },
        {
          header: 'PackType',
          key: 'PackType',
          width: 12,
        },
        {
          header: 'CountPerBundle',
          key: 'CountPerBundle',
          width: 8,
        },
        {
          header: 'UsedInBundle',
          key: 'CountInBundle',
          width: 8,
        },
        {
          header: 'Received',
          key: 'ReceivedCount',
          width: 8,
        },
        {
          header: 'Damaged',
          key: 'DamagedCount',
          width: 8,
        },
        {
          header: 'Shipped',
          key: 'ShippedCount',
          width: 8,
        },
        {
          header: 'Delivered',
          key: 'deliveredCount',
          width: 8,
        },
        {
          header: 'Status',
          key: 'status',
          width: 8,
        },
        {
          header: 'Action',
          key: 'action',
          width: 12,
        },
        {
          header: 'CreatedAt',
          key: 'creationTime',
          width: 12,
        },
      ]
      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:AB1'
      worksheet.views = [{
        state: 'frozen',
        xSplit: 0,
        ySplit: 1,
      }]

      const batchName = data.length > 0 ? data[0].batchName : 'Items'
      data.forEach(item => {
        worksheet.addRow([
          item.tracking,
          item.orderNumber,
          item.orderNumberSold,
          item.palletId,
          formatDate(item.purchaseDate),
          formatDate(item.shipmentDate),
          formatDate(item.expirationDate),
          item.asin,
          item.msku,
          item.upc,
          item.fnsku,
          item.serialNo,
          item.title,
          item.supplier,
          item.quantity,
          item.itemCondition,
          item.itemCost,
          item.salePrice,
          item.fragile,
          item.packType,
          item.countPerBundle,
          item.countInBundle,
          item.receivedCount,
          item.damagedCount,
          item.shippedCount,
          item.deliveredCount,
          item.status,
          item.action,
          formatDate(item.creationTime),
        ])
      })
      workbook.xlsx.writeBuffer()
        .then(buffer => {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            `${batchName}.xlsx`,
          )
        })
    },
    async downloadForUpload() {
      const filters = {
        companyId: 0,
        isUpc: false,
        statusList: this.filters.statusList,
        includeSortedItems: false,
        includeAllBatches: this.includeAllBatches,
        includeAllItems: true,
        batchId: this.batchId,
        q: '',
      }
      const pager = new Pager({ staticPageSize: 10000 })
      const response = await shipService.getBySearchList(filters, pager.staticPageable)
      const data = response.data
      this.onDownloadExportDataForUpload(data)
    },
    onDownloadExportDataForUpload(data) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Shipment Items')
      worksheet.columns = [
        {
          header: 'TrackingNumber',
          key: 'TrackingNumber',
          width: 14,
        },
        {
          header: 'OrderNumber',
          key: 'OrderNumber',
          width: 16,
        },
        {
          header: 'OrderNumberSold',
          key: 'OrderNumberSold',
          width: 16,
        },
        {
          header: 'PalletId',
          key: 'PalletId',
          width: 16,
        },
        {
          header: 'PurchaseDate',
          key: 'PurchaseDate',
          width: 12,
        },
        {
          header: 'ShipmentDate',
          key: 'ShipmentDate',
          width: 12,
        },
        {
          header: 'ExpirationDate',
          key: 'ExpirationDate',
          width: 12,
        },
        {
          header: 'ASIN',
          key: 'ASIN',
          width: 13,
        },
        {
          header: 'MSKU',
          key: 'MSKU',
          width: 18,
        },
        {
          header: 'UPC',
          key: 'UPC',
          width: 14,
        },
        {
          header: 'FNSKU',
          key: 'FNSKU',
          width: 13,
        },
        {
          header: 'SerialNo',
          key: 'SerialNo',
          width: 12,
        },
        {
          header: 'ProductTitle',
          key: 'Title',
          width: 80,
        },
        {
          header: 'ManufactureOrSupplier',
          key: 'Supplier',
          width: 18,
        },
        {
          header: 'ProductGroup',
          key: 'ProductGroup',
          width: 18,
        },
        {
          header: 'NumberOfUnits',
          key: 'Quantity',
          width: 8,
        },
        {
          header: 'Condition',
          key: 'Condition',
          width: 12,
        },
        {
          header: 'Cost',
          key: 'Cost',
          width: 12,
        },
        {
          header: 'SalePrice',
          key: 'SalePrice',
          width: 12,
        },
        {
          header: 'Fragile',
          key: 'Fragile',
          width: 8,
        },
        {
          header: 'MultiPack',
          key: 'MultiPack',
          width: 12,
        },
        {
          header: 'Bundle',
          key: 'Bundle',
          width: 12,
        },
        {
          header: 'CountPerBundle',
          key: 'CountPerBundle',
          width: 8,
        },
        {
          header: 'Action',
          key: 'action',
          width: 12,
        },
        {
          header: 'Notes',
          key: 'notes',
          width: 12,
        },
        {
          header: 'locationName',
          key: 'locationName',
          width: 12,
        },
      ]
      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:AB1'
      worksheet.views = [{
        state: 'frozen',
        xSplit: 0,
        ySplit: 1,
      }]

      const batchName = data.length > 0 ? data[0].batchName : 'Items'
      const date = moment() // Current date
      const formattedDate = date.format('YYYY-MM-DD')
      data.forEach(item => {
        worksheet.addRow([
          item.tracking,
          item.orderNumber,
          item.orderNumberSold,
          item.palletId,
          formattedDate, // purchase date
          formattedDate, // shipment date
          '', // expiration date
          item.asin,
          item.msku,
          item.upc,
          item.fnsku,
          item.serialNo,
          item.title,
          item.supplier,
          '',
          item.quantity,
          item.itemCondition,
          item.itemCost,
          item.salePrice,
          item.fragile,
          '',
          '',
          0,
          item.action,
          '',
          '',
        ])
      })
      workbook.xlsx.writeBuffer()
        .then(buffer => {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            `${batchName}_for_upload.xlsx`,
          )
        })
    },
    onOptionChanged(e) {
      if (e.fullName === 'searchPanel.text') {
        this.filters.query = ''
        this.filters.query = e.value
      }
    },
    formatStatus(status) {
      return ClientShipmentItemStatusEnum[status].text
    },
    searchByTracking(data) {
      if (data.data.tracking && data.data.tracking !== null && data.data.tracking !== '') {
        this.filters.query = data.data.tracking.trim()
        this.getBySearchTerm()
      }
    },
    searchByOrderNumber(data) {
      if (data.data.orderNumber && data.data.orderNumber !== null && data.data.orderNumber !== '') {
        this.filters.query = data.data.orderNumber.trim()
        this.getBySearchTerm()
      }
    },
    searchByPalletId(data) {
      if (data.data.palletId && data.data.palletId !== null && data.data.palletId !== '') {
        this.filters.query = data.data.palletId.trim()
        data.component.searchByText(this.filters.query)
        this.getBySearchTerm()
      }
    },
    goToBatch(data) {
      this.batchId = data.data.batchId
      this.batchName = data.data.batchName
      this.searchCode = ''
      this.filters.query = ''
      this.includeAllBatches = false
      this.$router.push({
        name: 'route-shipment-items',
        query: {
          batchId: data.data.batchId,
          batchName: data.data.batchName,
        },
      })
      this.initialLoad()
    },
    resolveBundleMultiPackVariant(val) {
      return val > 0 ? 'badge-danger' : ''
    },
    resolveShipDateVariant(data) {
      if (data.data.status === ClientShipmentItemStatusEnum.delivered.key || data.data.status === ClientShipmentItemStatusEnum.fulfilled.key || data.data.status === ClientShipmentItemStatusEnum.shipped.key) return 'success'
      if (data.data.status === ClientShipmentItemStatusEnum.archived.key || data.data.status === ClientShipmentItemStatusEnum.intransit.key) return 'normal'
      if (data.data.status === ClientShipmentItemStatusEnum.blocked.key) return 'danger'
      const days = Math.ceil(
        moment.duration(moment()
          .diff(moment(data.data.ShipmentDate)))
          .asDays(),
      )
      if (days < 14) return 'success'
      return 'default'
    },
    resolveResolution(resolution) {
      if (resolution === 'OPEN') return 'bg-primary'
      if (resolution === 'RESOLVED') return 'bg-success'
      if (resolution === 'CASE_INITIATED') return 'bg-warning'
      return ''
    },
    getBySearchTerm() {
      this.isDownloaded = false
      const filters = {
        companyId: 0,
        isUpc: false,
        statusList: this.filters.statusList,
        includeSortedItems: false,
        includeAllBatches: this.includeAllBatches,
        batchId: parseInt(this.batchId, 10),
        q: this.filters.query,
        beginDate: this.filters.beginDate,
        endDate: this.filters.endDate,
      }
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.reload()

      if (this.includeAllBatches && this.filters.query) {
        this.hideBatchName = true
      }
    },
    onCloseBatch() {
      this.$swal({
        title: 'Do you want to close this batch?',
        html: `
          <div class="">
            <div class="">
              <p>
                Are you sure you want to close this batch?
              </p>
              <p>
                Please know that <strong>items with 0 received count will be deleted.</strong> <br> Quantity for items with some received quantities will be set to number of received count.
              </p>
              <p>
                You won't be able to revert this!
              </p>
            </div>
          </div>
        `,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Close Batch',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then((async res => {
          if (res.value) {
            shipService.completeBatchById(this.batchId)
              .then(() => {
                Notify.success('This batch has been closed')
                this.dataSource.load()
              })
          }
        }))
    },
    deleteItem(e) {
      const confirmResult = confirm(
        'Are you sure you want to delete this Item record? You won\'t be able to revert this!',
        'Confirmation',
      )
      confirmResult.then(dialogResult => {
        if (dialogResult) {
          shipService.deleteItemById(e.row.key)
            .then(() => {
              const dataSource = this.dataGrid.getDataSource()
              dataSource.reload()
            })
        }
      })
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Shipment Items')
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true,
      })
        .then(() => {
          workbook.xlsx.writeBuffer()
            .then(buffer => {
              saveAs(
                new Blob([buffer], { type: 'application/octet-stream' }),
                'Items.xlsx',
              )
            })
        })
      e.cancel = true
    },
    async onReconcileExporting(e) {
      const filters = {
        companyId: 0,
        isUpc: false,
        statusList: this.filters.statusList,
        includeSortedItems: false,
        includeAllBatches: false,
        batchId: this.batchId,
        q: '',
      }
      const pager = new Pager({})
      const response = await shipService.getBySearchTerm(filters, pager.staticPageable)
      const data = response.data.content
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Shipment Items')
      worksheet.columns = [
        {
          header: 'Batch Id',
          key: 'ShipmentId',
          width: 18,
        },
        {
          header: 'Title',
          key: 'Title',
          width: 80,
        },
        {
          header: 'Tracking',
          key: 'TrackingNumber',
          width: 14,
        },
        {
          header: 'Order Number',
          key: 'OrderNumber',
          width: 16,
        },
        {
          header: 'Purchase Date',
          key: 'PurchaseDate',
          width: 12,
        },
        {
          header: 'Ship Date',
          key: 'ShipmentDate',
          width: 12,
        },
        {
          header: 'QTY',
          key: 'Quantity',
          width: 8,
        },
        {
          header: 'C/B, MP',
          key: 'CountPerBundle',
          width: 8,
        },
        {
          header: 'UsedInBundle',
          key: 'CountInBundle',
          width: 8,
        },
        {
          header: 'Received',
          key: 'ReceivedCount',
          width: 8,
        },
        {
          header: 'Damaged',
          key: 'DamagedCount',
          width: 8,
        },
        {
          header: 'Shipped',
          key: 'ShippedCount',
          width: 8,
        },
        {
          header: 'Delivered',
          key: 'deliveredCount',
          width: 8,
        },
        {
          header: 'Inventory',
          width: 8,
        },
        {
          header: 'Discrepancy',
          width: 8,
        },
        {
          header: 'Status',
          key: 'status',
          width: 8,
        },
        {
          header: 'ASIN',
          key: 'ASIN',
          width: 13,
        },
        {
          header: 'UPC',
          key: 'UPC',
          width: 14,
        },
        {
          header: 'FNSKU',
          key: 'FNSKU',
          width: 13,
        },
        {
          header: 'MSKU',
          key: 'MSKU',
          width: 18,
        },
        {
          header: 'Supplier',
          key: 'Supplier',
          width: 18,
        },
        {
          header: 'Condition',
          key: 'Condition',
          width: 12,
        },
        {
          header: 'Fragile',
          key: 'Fragile',
          width: 8,
        },
        {
          header: 'PackType',
          key: 'PackType',
          width: 12,
        },
        {
          header: 'Cost',
          key: 'Cost',
          width: 12,
        },
        {
          header: 'SalePrice',
          key: 'SalePrice',
          width: 12,
        },
      ]
      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:Y1'
      worksheet.views = [{
        state: 'frozen',
        xSplit: 1,
        ySplit: 1,
      }]

      data.forEach(item => {
        worksheet.addRow([
          item.batchName,
          item.title,
          item.tracking,
          item.orderNumber,
          formatDate(item.purchaseDate),
          formatDate(item.shipmentDate),
          item.quantity,
          item.countPerBundle,
          item.countInBundle,
          item.receivedCount,
          item.damagedCount,
          item.shippedCount,
          item.deliveredCount,
          item.receivedCount - item.shippedCount,
          item.receivedCount - item.quantity,
          item.status,
          item.asin,
          item.upc,
          item.fnsku,
          item.msku,
          item.supplier,
          item.itemCondition,
          item.fragile,
          item.packType,
          item.itemCost,
          item.salePrice,
        ])
      })
      workbook.xlsx.writeBuffer()
        .then(buffer => {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            'Items.xlsx',
          )
        })
      e.cancel = true
    },
    calculateInventory(row) {
      return row.receivedCount - row.shippedCount
    },
    calculateDiscrepancy(row) {
      return row.receivedCount - row.quantity
    },
    getShipmentPlanStats() {
      this.deliveredPercent = 0
      shipService.getShipmentPlanStats(this.batchId)
        .then(result => {
          const data = result
          this.batchStatPercents.push(data.receivedPercent)
          this.batchStatPercents.push(data.fulfilledPercent)
          this.batchStatPercents.push(data.inTransitPercent)
          this.batchStatPercents.push(data.damagedPercent)

          this.batchStatCounts.push(data.receivedQuantity)
          this.batchStatCounts.push(data.fulfilledQuantity)
          this.batchStatCounts.push(data.inTransitQuantity)
          this.batchStatCounts.push(data.damagedQuantity)

          this.totalBatchCost = data.totalCost
          this.totalBatchSalePrice = data.totalSalePrice
          this.batchSellRatio = data.batchSellRatio
          this.avgCost = data.avgCost
          this.avgSalePrice = data.avgSalePrice
          this.deliveredPercent = data.receivedPercent
        })
    },
    onClickMoveSelectedItem() {
      const selectedRows = this.dataGrid.getSelectedRowsData()
      if (selectedRows.length > 0) {
        this.selectedRows = [...selectedRows]
        this.popupVisible = true
      } else {
        Notify.warning('Please select items.')
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      const items = [...this.selectedRows]
      const params = {
        batchName: this.moveItems.batchName,
        items: items,
      }
      if (params.batchName === '' || params.items.length === 0) return

      shipService.moveItems(params)
        .then(() => {
          Notify.success(
            `Items have been moved to the ${this.moveItems.batchName} batch successfully.`,
          )
          this.dataGrid.clearSelection()
          this.popupVisible = false
          this.reload()
        })
    },
    width() {
      return window.innerWidth / 2
    },

    onClickAddItem() {
      this.itemId = null
      this.selectedBatchId = Number(this.batchId)
      const popup = this.$refs.createOrEditItemPopupRef.instance
      popup.show()
    },
    editSelectedItem(e) {
      this.itemId = e.row.data.id
      this.selectedBatchId = e.row.data.batchId
      this.shipmentItemFormComponentId = uuidv4()
    },
    onCreateOrUpdateItem(item) {
      this.itemId = null
      this.selectedBatchId = 0
    },
    onBlockUnblockAction(row) {
      const data = { ...row.data }
      const formData = {
        productId: 0,
        status: 0,
        notes: '',
      }
      this.itemStatusUpdatePopupForm = { ...formData }
      const popup = this.$refs.itemStatusUpdatePopupRef.instance
      this.itemStatusUpdatePopupForm.productId = data.productId
      if (data.status !== ClientShipmentItemStatusEnum.blocked.key) {
        this.itemStatusUpdateTitle = 'Block'
        this.itemStatusUpdatePopupForm.notes = 'This item can not be replenished to Amazon due to the Storage Limits.'
        this.itemStatusUpdatePopupForm.status = ClientShipmentItemStatusEnum.blocked.value
      } else {
        this.itemStatusUpdateTitle = 'Unblock'
        this.itemStatusUpdatePopupForm.notes = 'This item is now eligible to replenish to Amazon due to available storage capacity.'
        this.itemStatusUpdatePopupForm.status = ClientShipmentItemStatusEnum.intransit.value
      }
      popup.show()
    },
    async handleBlockUnblockItemStatus() {
      const form = this.$refs.itemStatusUpdatePopupFormRef.instance
      const popup = this.$refs.itemStatusUpdatePopupRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        try {
          await shipService.updateItemStatus(this.itemStatusUpdatePopupForm)
        } catch (err) {
          Notify.error(err.message)
        }
        popup.hide()
        this.reload()
      }
    },
    onMskuAction(data) {
      this.selectedProductId = data.row.data.productId
      this.selectedUpc = data.row.data.upc
      this.selectedAsin = data.row.data.asin
      this.selectedItemId = data.row.data.id
      const popup = this.$refs.setMskuPopupRef.instance
      popup.show()
    },
    setMskuFnskuPair(row) {
      const data = {
        id: row.id,
        productId: row.productId,
        msku: row.msku,
        fnsku: row.fnsku,
      }
      const popup = this.$refs.setMskuPopupRef.instance
      shipService.updateMskuFnsku(data, this.selectedItemId)
        .then(() => {
          Notify.success('MSKU/FNSKU has been set successfully.')
          popup.hide()
          this.reload()
        })
        .catch(error => {
          Notify.error(`MSKU/FNSKU set failed: ${error}`)
        })
    },
    createMskuFnskuPair(e) {
      const data = {
        id: null,
        productId: this.selectedProductId,
        msku: this.msku,
        fnsku: this.fnsku,
      }
      const result = e.validationGroup.validate()
      const popup = this.$refs.setMskuPopupRef.instance
      if (result.isValid) {
        shipService.updateMskuFnsku(data, this.selectedItemId)
          .then(() => {
            Notify.success('MSKU/FNSKU has been created successfully.')
            popup.hide()
            this.reload()
            e.validationGroup.reset()
          })
          .catch(error => {
            Notify.error(`MSKU/FNSKU generation failed: ${error}`)
          })
      }
    },
    async onSetMskuPopupShowing() {
      const res = await productsService.fetchCodesById(this.selectedProductId)
      this.skuPairs = res.data
    },
    onSetMskuPopupHiding() {
      this.msku = ''
      this.fnsku = ''
      this.selectedProductId = null
      this.selectedItemId = null
    },
    openProductDetails(e) {
      this.selectedProductId = e.productId
      this.isVisible = true
    },
    formatDiscrepancyCell(v) {
      if (v < 0) return 'badge badge-danger d-block'
      if (v > 0) return 'badge badge-warning d-block'
      return 'badge d-block'
    },
    async loadDataGridState(e) {
      const dataGridKey = DataGridNamesEnum.INBOUND_SHIPMENT_ITEMS_GRID.key
      const result = await settingsService.getByReference(referenceTypeEnum.USER.value, this.userId, dataGridKey)
      this.currentGridStateId = result.data.id
      this.dataGridState = {}
      if (result.data.value) {
        const state = JSON.parse(result.data.value)
        state.searchText = ''
        this.dataGridState = state
      }
      return this.dataGridState
    },
    async saveDataGridState(e) {
      if (e === undefined || e === null) {
        return
      }
      if (JSON.stringify(e) === JSON.stringify(this.dataGridState)) {
        return
      }
      if (e.searchText !== '' || this.isSearchAction) {
        this.isSearchAction = false
        return
      }
      const dataGridPreferences = { ...e }

      dataGridPreferences.selectedRowKeys = []
      dataGridPreferences.searchText = ''
      const dataGridKey = DataGridNamesEnum.INBOUND_SHIPMENT_ITEMS_GRID.key
      const preferenceData = {
        companyId: this.userCompanyId,
        referenceId: this.userId,
        referenceType: referenceTypeEnum.USER.value,
        settingType: SettingsTypeEnum.DATAGRID_STATES.value,
        settingKey: dataGridKey,
        description: `State of the ${dataGridKey} for UserId: ${this.userId}`,
        settingName: dataGridKey,
        value: JSON.stringify(dataGridPreferences),
      }
      await settingsService.createOrUpdate(preferenceData)
    },
    async updateResolutionStatus(e, id) {
      const status = e.value
      await shipService.resolveItemStatus(id, status)
      this.dataSource.reload()
    },
    deleteBatchItemsInBulk() {
      const rowIds = this.dataGrid.getSelectedRowKeys()
      if (rowIds.length === 0) {
        Notify.danger('Please select at least one item to delete.')
        return
      }
      this.$swal({
        title: 'Do you want to delete these items?',
        text: `This action cannot be reversed. Delete items count: ${rowIds.length}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then((async res => {
          if (res.value) {
            await shipService.deleteManyItemsById(rowIds)
            await this.getBySearchTerm()
          }
        }))
    },
  },
}
</script>

<style lang="scss">
#shipmentItemsGrid {
  .dx-toolbar-before {
    align-items: center;
  }
}

#infoToolbar {
  .media-heading {
    color: white !important
  }

  .b-icon.bi {
    font-size: 2rem;
  }
}

.custom-progress-bar .dx-progressbar-range {
  height: 20px;
  background-color: rgb(54, 170, 54);
}

.custom-progress-bar .dx-progressbar-container {
  height: 22px;
}

.dx-progressbar-status {
  font-size: 16px;
  position: absolute;
  top: 0px;
  left: 22px;
}

#btn-create-new {
  color: #00cccc;
  background-color: rgba(0, 204, 204, 0.05);
  .dx-icon {
    font-size: 1.3rem;
    color: #00cccc;
    margin-right: 0.5rem;
  }
}

#btn-delete-items {
  color: #d75645;
  background-color: rgba(203, 64, 36, 0.05);
  .dx-icon {
    font-size: 1.3rem;
    color: #d75645;
    margin-right: 0.5rem;
  }
}

#btn-reconcile {
  color: #417fb4;
  background-color: rgba(28, 84, 131, 0.05);
  .dx-icon {
    font-size: 1.3rem;
    color: #417fb4;
    margin-right: 0.5rem;
  }

}

#btn-close {
  color: #d99338;
  background-color: rgba(217, 112, 56, 0.05);
  .dx-icon {
    font-size: 1.3rem;
    color: #d99338;
    margin-right: 0.5rem;
  }
}

</style>
