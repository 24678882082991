/* eslint-disable no-unused-vars */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import shipService from '@/http/requests/ship/shipService'
import Pager from '@core/dev-extreme/utils/pager'
import { itemImageLink } from '@/libs/app/catalog-item'

function sortFieldMap(sort) {
  const sortBy = []
  sort.forEach(c => {
    let field = c.selector
    if (c.selector === 'TrackingNumber') field = 'tracking'
    if (c.selector === 'NumberOfUnits') field = 'number_of_units'
    if (c.selector === 'OrderNumber') field = 'order_number'
    if (c.selector === 'ProductTitle') field = 'product_title'
    if (c.selector === 'purchaseDate') field = 'purchase_date'
    if (c.selector === 'shipmentDate') field = 'shipment_date'
    if (c.selector === 'CountPerBundle') field = 'count_per_bundle'
    if (c.selector === 'sortedCount') field = 'sorted'
    sortBy.push({ selector: field, desc: c.desc })
  })
  return sortBy
}

const shipmentItemsStore = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? sortFieldMap(loadOptions.sort) : [{ selector: 'id', desc: false }]
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    pager.setSortQuery(sort)

    const pageableQuery = `${pager.toCreatePageable()}&sort=${pager.toCreateSortOrder()}`
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : {}
    const response = await shipService.getBySearchTerm(filters, pageableQuery)
    const data = response.data
    return {
      data: data.content,
      totalCount: data.totalElements,
    }
  },
  byKey: key => shipService
    .fetchBatchById(key).then(response => {
      const data = response.data
      return data
    }),
  remove: key => {
    shipService.deleteBatchById(key)
  },
})

const shipmentItemsSource = new DataSource({
  store: shipmentItemsStore,
})

export {
  shipmentItemsSource,
}
