<template>
  <div class="">
    <div v-if="!editable" class="d-flex justify-content-around">
      <span
        role="button"
        class="font-weight-bold d-block text-nowrap text-info"
        @click="$emit(`emit-search-by-${fieldName}`, row)"
      >
        {{ row.value }}
      </span>
      <p-icon role="button" name="bi-pencil-square" class="text-primary" @click.native="editable = true" />
    </div>
    <dx-util-text-box
      v-else
      :ref="`textbox-update-${row.data.id}`"
      :value="row.value"
      width="130"
      @content-ready="setEditable"
      @key-down="onKeyDown($event, row)"
      @enter-key="onEnterKey"
      @value-changed="updateTrackingNumber"
    >
      <dx-text-box-button
        :name="`update-${row.data.id}`"
        :options="{
          icon: 'save',
          type: 'success',
          elementAttr: { id: `update-${row.data.id}` },
          onClick: () => onUpdateTracking(row)
          }"
        location="after"
      />
  </dx-util-text-box>
  </div>
</template>

<script>
import shipService from '@/http/requests/ship/shipService'
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
// import inventoryService from '@/http/requests/inventory/inventoryService'
// import { Notify } from '@robustshell/utils/index'

export default {
  components: {
    'dx-text-box-button': DxTextBoxButton,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    fieldName: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      editable: false,
      updateValue: '',
    }
  },
  mounted() {
    this.updateValue = this.row.value
  },
  methods: {
    setEditable(e) {
      e.component.focus()
    },
    async onUpdateTracking(row) {
      if (!row.data.id) return
      const item = { ...row.data }
      const payload = {
        id: item.id,
        [this.fieldName]: this.updateValue,
      }

      await shipService.upsertItem(payload)
      this.$emit('emit-updated')
      this.editable = false
    },
    onKeyDown(e) {
      const escapeKey = 27
      if (this.editable && e.event.which === escapeKey) {
        this.editable = false
      }
    },
    onEnterKey() {
      this.onUpdateTracking(this.row)
    },
    updateTrackingNumber(e) {
      this.updateValue = e.value
    },
  },

}
</script>

<style lang="scss" scoped>

</style>
